import { combineReducers } from 'redux';
import alert from './alert';
import calendar from './calendar';
import register from './register';

export default combineReducers({
	alert,
	calendar,
	register,
});
