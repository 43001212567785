import React, { lazy, Suspense, Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { LoadingGif } from './components/elements/Loading';
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser, logout } from './actions/register';
import './components/styles/main.css';

const Alerts = lazy(() => import('./components/elements/Alerts'));

const Landing = lazy(() => import('./components/Landing'));
const Calendar = lazy(() => import('./components/Pages/Calendar'));
const Home = lazy(() => import('./components/Pages/Home'));

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

const App = () => {
	useEffect(() => {
		store.dispatch(loadUser());
		const handleInvalidToken = e => {
			if (e.key === 'token' && e.oldValue && !e.newValue) {
				store.dispatch(logout());
			}
		};
		window.addEventListener('storage', handleInvalidToken);
		return function cleanup() {
			window.removeEventListener('storage', handleInvalidToken);
		};
	}, []);
	return (
		<Provider store={store}>
			<Router>
				<Suspense fallback={LoadingGif}>
					<Fragment>
						<section>
							<Alerts />
							<Routes>
								<Route exact path='/' element={<Landing />} />
								<Route exact path='/home' element={<Home />} />
								<Route exact path='/:calendarId' element={<Calendar />} />
								<Route path='*' element={() => <Navigate to={'/'} />} />
							</Routes>
						</section>
					</Fragment>
				</Suspense>
			</Router>
		</Provider>
	);
};

export default App;
