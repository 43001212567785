import React from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const updateDataOfBlock = (editorState, block, newData, triggerUpdateDb) => {
	const contentState = editorState.getCurrentContent();
	const newBlock = block.merge({
		data: newData,
	});
	const newContentState = contentState.merge({
		blockMap: contentState.getBlockMap().set(block.getKey(), newBlock),
	});
	const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');
	triggerUpdateDb(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())));
	return newEditorState;
};

export const TodoBlock = props => {
	return props.children.map((child, index) => {
		const updateData = () => {
			const { block, blockProps } = child.props.children.props;
			const data = block.getData();
			const checked = data.has('checked') && data.get('checked') === true;
			const newData = data.set('checked', !checked);
			const { onChange, getEditorState, triggerUpdateDb } = blockProps;
			onChange(updateDataOfBlock(getEditorState(), block, newData, triggerUpdateDb));
		};
		let completed = child.props.children.props.block.getData().get('checked') ?? false;
		return (
			<ListItem key={index} style={{ padding: '0', margin: '0', marginLeft: '1rem' }}>
				<ListItemIcon style={{ minWidth: '0' }}>
					<Checkbox
						checked={completed}
						color='primary'
						onChange={updateData}
						style={{ padding: '0.1rem' }}
					/>{' '}
				</ListItemIcon>
				<ListItemText style={{ textDecoration: completed ? 'line-through' : null, margin: '0' }}>
					{child}
				</ListItemText>
			</ListItem>
		);
	});
};
