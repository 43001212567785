import { createTheme } from '@material-ui/core/styles';
import FactCheckIcon from '@material-ui/icons/ListAlt';
import { TodoBlock } from '../components/elements/customElements/TodoBlock';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGOUT = 'LOGOUT';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAIL = 'VERIFICATION_FAIL';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';
export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const CALENDAR_SETTINGS = 'CALENDAR_SETTINGS';
export const CLEAN_CALENDAR = 'CLEAN_CALENDAR';
export const LOAD_FAIL = 'LOAD_FAIL';
export const CALENDAR_UPDATE = 'CALENDAR_UPDATE';
export const CLEAR_CALENDAR_ID = 'CLEAR_CALENDAR_ID';
export const USER_STORE_UPDATE = 'USER_STORE_UPDATE';
export const USER_SINGLE_UPDATE = 'USER_SINGLE_UPDATE';
export const CALENDAR_VIEW = 'CALENDAR_VIEW';
export const CALENDAR_REFRESH = 'CALENDAR_REFRESH';
export const WEEK_VIEW_TYPE = 'WEEK_VIEW_TYPE';

export const EVENTS_LOADED = 'EVENTS_LOADED';
export const AGENDA_LOADED = 'AGENDA_LOADED';
export const NEW_USER_ADDED = 'NEW_USER_ADDED';
export const UPDATE_EVENTS = 'UPDATE_EVENTS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOAD_FAIL = 'USER_LOAD_FAIL';
export const USER_UPDATE = 'USER_UPDATE';

export const PING_RECEIVED = 'PING_RECEIVED';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const DESTROY_SOCKET = 'DESTROY_SOCKET';

export const ADD_EVENT_SOCKET = 'ADD_EVENT_SOCKET';
export const UPDATE_EVENT_SOCKET = 'UPDATE_EVENT_SOCKET';
export const DELETE_EVENT_SOCKET = 'DELETE_EVENT_SOCKET';

export const USER_CALENDAR_CREATED = 'USER_CALENDAR_CREATED';

let defaultTheme = createTheme();

Object.assign(defaultTheme, {
	overrides: {
		MUIRichTextEditor: {
			root: {
				width: '97%',
				marginLeft: '0.5rem',
			},
			editor: {
				height: '20vh',
				overflow: 'auto',
				border: '1px solid gray',
			},
		},
	},
});

export { defaultTheme };

export const palette = {
	monalisa: '#FE938C',
	darkblue: '#0693E3',
	tradewind: '#4FAAA1',
	lavendar: ' #A09FE6',
	frenchpass: '#B8F4FF',
	sisal: '#D5CFC0',
	space: '#2C3539',
	trendypink: '#90749B',
	steelblue: '#4281A4',
	cashmere: '#E6B89C',
	danube: '#5FA3C8',
};

export const rteControls = [
	'bold',
	'numberList',
	'bulletList',
	'link',
	'undo',
	'redo',
	'strikethrough',
	'todo',
];

export const todoComponent = {
	name: 'todo',
	icon: <FactCheckIcon />,
	type: 'block',
	blockWrapper: <TodoBlock />,
};
