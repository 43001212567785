//Authentication is handled here, please refer to auth.js in utils for request logic

import {
	CREATE_SUCCESS,
	CREATE_FAIL,
	LOAD_FAIL,
	LOAD_SUCCESS,
	EVENTS_LOADED,
	AGENDA_LOADED,
	CALENDAR_UPDATE,
	CLEAN_CALENDAR,
	LOGOUT,
	UPDATE_EVENTS,
	CALENDAR_SETTINGS,
	CLEAR_CALENDAR_ID,
	NEW_USER_ADDED,
	USER_STORE_UPDATE,
	ADD_EVENT_SOCKET,
	UPDATE_EVENT_SOCKET,
	DELETE_EVENT_SOCKET,
	CALENDAR_VIEW,
	CALENDAR_REFRESH,
	WEEK_VIEW_TYPE,
} from '../utils/consts';

const initialState = {
	loading: null,
	calendarName: null,
	calendarId: null,
	users: [],
	events: [],
	calendarSettings: {},
	userStore: {},
	view: 'week',
	weekViewType: 'current',
	refresh: false,
};

export default function (state = initialState, action) {
	let { type, payload } = action;
	switch (type) {
		case LOAD_SUCCESS:
		case CREATE_SUCCESS:
			return {
				...state,
				...payload,
				calendarId: payload.uid,
				loading: false,
			};
		case EVENTS_LOADED:
			if (state.calendarSettings && Object.keys(state.calendarSettings).length > 0)
				payload.forEach(event => {
					event.color = state.calendarSettings[event.uid]?.color;
					event.isVisible = state.calendarSettings[event.uid]?.isVisible;
					event.start = new Date(Date.parse(event.start));
					event.end = new Date(Date.parse(event.end));
				});
			else
				payload.forEach(event => {
					event.start = new Date(Date.parse(event.start));
					event.end = new Date(Date.parse(event.end));
				});
			return {
				...state,
				events: [...state.events, ...payload],
			};
		case AGENDA_LOADED:
			return {
				...state,
				agenda: { ...payload },
			};
		case CALENDAR_VIEW:
			return {
				...state,
				view: payload,
			};
		case WEEK_VIEW_TYPE:
			return {
				...state,
				weekViewType: payload,
			};
		case CALENDAR_REFRESH:
			return {
				...state,
				refresh: payload,
			};
		case CALENDAR_SETTINGS:
			state.events.forEach(event => {
				event.color = payload[event.uid]?.color;
				event.isVisible = payload[event.uid]?.isVisible;
			});
			return {
				...state,
				calendarSettings: payload,
			};
		case USER_STORE_UPDATE:
			let userStore = state.userStore;
			payload.forEach(userData => {
				userStore = {
					...userStore,
					[userData._id]: { avatar: userData.avatar, name: userData.name },
				};
			});
			return {
				...state,
				userStore,
			};
		case NEW_USER_ADDED:
			if (!payload.isHome) {
				state.users.push(payload.data);
			}
			return {
				...state,
			};
		case CLEAN_CALENDAR:
			return {
				view: 'week',
				calendarName: null,
				calendarId: null,
				users: [],
				events: [],
				weekViewType: 'current',
			};
		case UPDATE_EVENTS:
			return {
				...state,
				events: payload,
			};
		case ADD_EVENT_SOCKET:
			let eventsCopyAdd = [...state.events];
			payload.eventData.start = new Date(Date.parse(payload.eventData.start));
			payload.eventData.end = new Date(Date.parse(payload.eventData.end));
			payload.eventData.color = state.calendarSettings[payload.eventData.uid]?.color;
			payload.eventData.isVisible = state.calendarSettings[payload.eventData.uid]?.isVisible;
			eventsCopyAdd = [...state.events, payload.eventData];
			return {
				...state,
				events: eventsCopyAdd,
			};
		case UPDATE_EVENT_SOCKET:
			let eventsCopyUpdate = [...state.events];
			const eventUdpateIndex = eventsCopyUpdate.findIndex(
				event => event.id === payload.eventData.id
			);
			if (eventUdpateIndex !== -1) {
				eventsCopyUpdate[eventUdpateIndex].title = payload.eventData.title;
				eventsCopyUpdate[eventUdpateIndex].description = payload.eventData.description;
				eventsCopyUpdate[eventUdpateIndex].start = new Date(Date.parse(payload.eventData.start));
				eventsCopyUpdate[eventUdpateIndex].end = new Date(Date.parse(payload.eventData.end));
				if (payload.eventData.uid !== eventsCopyUpdate[eventUdpateIndex].uid) {
					eventsCopyUpdate[eventUdpateIndex].uid = payload.eventData.uid;
					eventsCopyUpdate[eventUdpateIndex].color =
						state.calendarSettings[eventsCopyUpdate[eventUdpateIndex].uid]?.color;
					eventsCopyUpdate[eventUdpateIndex].isVisible =
						state.calendarSettings[eventsCopyUpdate[eventUdpateIndex].uid]?.isVisible;
				}
			} else {
				payload.eventData.start = new Date(Date.parse(payload.eventData.start));
				payload.eventData.end = new Date(Date.parse(payload.eventData.end));
				payload.eventData.color = state.calendarSettings[payload.eventData.uid]?.color;
				payload.eventData.isVisible = state.calendarSettings[payload.eventData.uid]?.isVisible;
				eventsCopyUpdate = [...eventsCopyUpdate, payload.eventData];
			}
			return {
				...state,
				events: eventsCopyUpdate,
			};
		case DELETE_EVENT_SOCKET:
			const deletedEventCopy = [...state.events];
			const eventUpdateIndex = deletedEventCopy.findIndex(event => event.id === payload.eventId);
			deletedEventCopy.splice(eventUpdateIndex, 1);
			return {
				...state,
				events: deletedEventCopy,
			};
		case CALENDAR_UPDATE:
			return {
				...state,
				...payload,
			};
		case CLEAR_CALENDAR_ID:
			return {
				...state,
				calendarId: null,
			};
		case CREATE_FAIL:
		case LOGOUT:
		case LOAD_FAIL:
			return {
				state: null,
			};
		default:
			return state;
	}
}
