//Authentication is handled here, please refer to auth.js in utils for request logic

import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	USER_LOAD_FAIL,
	LOGIN_SUCCESS,
	NEW_USER_ADDED,
	LOGIN_FAIL,
	VERIFICATION_SUCCESS,
	USER_UPDATE,
	VERIFICATION_FAIL,
	LOGOUT,
} from '../utils/consts';

const initialState = {
	token: localStorage.getItem('token'),
	isAuthenticated: null,
	user: {},
	verified: null,
	loading: null,
	myCalendars: [],
	myCalendar: null,
	fullColoredTasks: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case USER_LOADED:
		case LOGIN_SUCCESS:
			if (type === LOGIN_SUCCESS) localStorage.setItem('token', payload.token);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				verified: payload.confrimed,
			};
		case REGISTER_SUCCESS:
			localStorage.setItem('token', payload.token);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
			};
		case VERIFICATION_SUCCESS:
			localStorage.removeItem('token');
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				verified: true,
			};
		case USER_UPDATE:
			return {
				...payload,
			};
		case NEW_USER_ADDED:
			let myCalendarsToUpdate = [...state.myCalendars];
			if (payload.isHome) {
				myCalendarsToUpdate
					.find(myCal => myCal.calendar.uid === payload.uid)
					.calendar.users.push(payload.data);
			}
			return {
				...state,
				myCalendars: myCalendarsToUpdate,
			};
		case VERIFICATION_FAIL:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				verified: false,
			};
		case REGISTER_FAIL:
		case LOGIN_FAIL:
		case USER_LOAD_FAIL:
		case LOGOUT:
			localStorage.removeItem('token');
			return {
				...state,
				...payload,
				isAuthenticated: false,
				loading: false,
				verified: null,
			};
		default:
			return state;
	}
}
